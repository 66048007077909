import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const Nav = (props) => (
    <nav id="nav" className={props.sticky ? 'alt' : ''}>
        <Scrollspy items={ ['intro', 'first', 'second', 'cta'] } currentClassName="is-active" offset={-300}>
            <li>
                <Scroll type="id" element="intro">
                    <a href="#">Účinky masáže</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="offer">
                    <a href="#">Nabídka</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="gallery">
                    <a href="#">Galerie</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="contact">
                    <a href="#">Kontakt</a>
                </Scroll>
            </li>
        </Scrollspy>
    </nav>
)

export default Nav
