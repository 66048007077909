import React, { Component } from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'
import pic01 from '../assets/images/pic01.jpg'
import picametyst from '../assets/images/picametyst.jpg'
import mas001 from '../assets/images/mas001.jpg'
import mas002 from '../assets/images/mas002.jpg'
import mas003 from '../assets/images/mas003.jpg'
import mas004 from '../assets/images/mas004.jpg'
import mas005 from '../assets/images/mas005.jpg'
import mas006 from '../assets/images/mas006.jpg'
import mas007 from '../assets/images/mas007.jpg'
import mas008 from '../assets/images/mas008.jpg'
import mas009 from '../assets/images/mas009.jpg'

import styled from 'styled-components';
import Lightbox from 'lightbox-react';
import 'lightbox-react/style.css'; // This only needs to be imported once in your app


// pocet souboru v galerii (pojmenovane)
const NUMBER_OF_FILES = 20;

const width = 325;
const height = 245;

const Thumb = styled.div`
  margin-right: 15px;
  margin-bottom: 15px;
  width: ${width}px;
  height: ${height}px;
  @media (min-width: 1280px) {
    &:nth-last-child(3n + 1) {
      margin-right: 0;
    }
  }
`

const GalleryItem = styled.div`
  position: relative;
  color: white;
  line-height: 245px;

`

const HoverThumb = styled.div`
  z-index 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  width: ${width}px;
  height: ${height}px;
  opacity: 0;
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: RGBA(71, 18, 68, .5);
    opacity: 1;
  }

`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 1280px) {
    // 3 * Thumb width + 2 * margin
    width: calc((3 * ${width}px) + (2 * 15px));
  }

  @media (max-width: 1280px) and (min-width: 896px) {
    width: calc((2 * ${width}px) + (2 * 15px));
  }


  @media (max-width: 895px) {
    width: calc(${width}px + (2 * 15px));
  }


  justify-items: center;

  margin: auto;
`


class Gallery extends Component {
  constructor() {
    super();

    let galleryItems = []
    for(let i = 0; i < NUMBER_OF_FILES; i++) {
      try{
        galleryItems.push((
          <GalleryItem>
            <img src={require(`../assets/images/gallery/small/ametyst-${i > 9 ? i : '0' + i}.jpg`)} alt="Masáže Ametyst" className="image" />
            <HoverThumb>
              <i className="fa fa-diamond" aria-hidden="true"></i>
               Zobrazit více
            </HoverThumb>
          </GalleryItem>
        ))
      } catch (e) {
        // soubor neexistuje
      }
    }

    this.state = {
      currentIndex: 0,
      items: galleryItems
    };
  }

  render() {
    return (
      <div>
        <Grid>
          {this.state.items.map((item, i) =>
            <Thumb key={i} onClick={() => this.props.galleryHandler({ isOpen: true, photoIndex: i })}>{item}</Thumb>)}
        </Grid>
      </div>
    );
  }
}
const GalleryPreview = styled.img`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
       
    @media (max-width: 1280px) {
       width: 100%;
    }
`


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      photoIndex: 0
    }
  }

  galleryHandler = ({isOpen, photoIndex}) => {
    this.setState({isOpen})
    this.setState({photoIndex})
  }

  render() {


    const { photoIndex, isOpen } = this.state;


    let images = []
    for(let i = 0; i < NUMBER_OF_FILES; i++) {
      try{
        images.push(<GalleryPreview src={require(`../assets/images/gallery/desktop/ametyst-${i > 9 ? i : '0' + i}.jpg`)} alt="Masáže Ametyst" className="image" />)
      } catch (e) {
        // soubor neexistuje
      }
    }


    return (
      <Layout>
        <Helmet title="Masáže Ametyst" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav} />
        <div id="main">
        <span className="image main" style={{backgroundImage: `url(${picametyst})`}}></span>

          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Účinky masáže</h2>
                </header>
                <p><em>V každé bytosti proudí energie, jež působí do hloubky přes povrch těla až na vnitřní orgány. Příznivým vlivem masáže se svaly uvolňují, prokrvují a navrací zpátky rovnováhu po fyzické či psychické zátěži.</em></p>
                <p><em>Z široké nabídky masáží si můžete zvolit tu, kde najdete vše, co nyní potřebujete a budete se cítit jako znovuzrozeni. </em></p>
                <ul className="actions">

                  <li><Link to="/recenze" className="button">Zobrazit recenze</Link> </li>
                </ul>
              </div>
              <span className="image"><img src={pic01} alt="" /></span>
            </div>
          </section>

          <section id="offer" className="main special">
            <header className="major">
              <h2>Nabídka služeb</h2>

            </header>
            <p><em>Pro všechny techniky platí, že dotekem či masáží se aktivují samoléčebné síly, tímto se uvolňuje napětí.</em></p>
            <ul className="features">
              <li>
                <span className="image" ><img src={mas001} alt="" /></span>
                <h3>Sportovně regenerační masáž</h3>
                <h4><bold>60 min. / 700 Kč</bold></h4>
                <p>Zlepšuje oběh lymfatického systému, prokrví a odstraní únavu svalů, tímto opětovně zregeneruje síly po fyzické námaze.</p>
              </li>
              <li>
              <span className="image"><img src={mas002} alt="" /></span>
                <h3>Masáž lávovými kameny</h3>
                <h4>60 min. / 700 Kč</h4>
                <p>Uvolňující teplo lávových kamenů harmonizuje tělo i mysl, díky postupnému odbourávání energetických bloků.</p>
              </li>
              <li>
              <span className="image"><img src={mas003} alt="" /></span>
                <h3>Baňkování</h3>
                <h4>60 min. / 700 Kč</h4>
                <p>Tato staletím prověřená metoda pocházející z Číny příznivě působí na vnitřní orgány, ulevuje od bolesti a pozitivně působí na dýchací cesty.</p>
              </li>
              <li>
              <span className="image"><img src={mas004} alt="" /></span>
                <h3>Kombinovaná masáž kameny a baňky</h3>
                <h4>60 min. / 700 Kč</h4>
                <p>Zbavte se stresu a vnitřního neklidu během kombinované masáže, jež vznikne prokrvením pokožky a zklidněním mysli.</p>
              </li>
              <li>
              <span className="image"><img src={mas005} alt="" /></span>
                <h3>Thajská olejová masáž</h3>
                <h4>120 min. / 1 700 Kč</h4>
                <p>Relaxační masáž využívá metody pomalých hmatů s působením na energetické centra, odbourává vyčerpání, uvolňuje napětí, a navíc posiluje imunitní systém.</p>
              </li>
              <li>
              <span className="image"><img src={mas006} alt="" /></span>
                <h3>Rituál Tokoriki</h3>
                <h4>120 min. / 1 700 Kč</h4>
                <p>Masáž horkými mušlemi původem z Filipín má blahodárné účinky na krevní systém a očišťuje lymfatický systém od škodlivých látek. Stres a napětí se příjemně odplaví pryč.</p>
              </li>
              <li>
              <span className="image"><img src={mas007} alt="" /></span>
                <h3>Tygrování</h3>
                <h4>20 min. / 350 Kč</h4>
                <p>Pomocí speciální kovových trubiček s bylinnou směsí - tygrů, snadno zaženete pocity chladu, pocítíte detoxikační účinky, které zmírňují otoky, bolesti a zlepšuje trávení.</p>
              </li>
              <li>
              <span className="image"><img src={mas008} alt="" /></span>
                <h3>Relaxace</h3>
                <h4>45 min. / 600 Kč</h4>
                <p>Olejová masáž zklidňuje nervový systém, zamezuje napětí vyvolané stresem, které se týká především bolestí zad a šíje. Načerpejte nové síly a mějte více energie.</p>
              </li>
              <li>
              <span className="image"><img src={mas009} alt="" /></span>
                <h3>Kosmetické produkty</h3>
                <h4>Na přání</h4>
                <p>Nabízím vše pro Vaši krásu, zdraví, spokojenost od značky Avon, Essence a Salos. Dopřejte si tu nejlepší péči, co můžete v pohodlí domova.</p>
              </li>
            </ul>
            <footer className="major">
            <ul className="statistics">
              <li className="style1">
               <li className="centered">
                 <div className="gift-wrapper">
                   <span className="icon fa-gift"></span>
                   <div className="text bold">
                    <h3-white>Dárkové poukazy dle Vašeho přání</h3-white>
                   </div>
                 </div>
                </li>

              </li>

            </ul>
            </footer>
          </section>

          <section id="gallery" className="main special">
          <header className="major">
          <h2>Galerie</h2>
                </header>
            <Gallery galleryHandler={this.galleryHandler}></Gallery>

          </section>



        </div>


        {isOpen && (
          <Lightbox
            enableZoom={false}
            mainSrc={images[photoIndex]} //tady
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </Layout>
    )
  }
}

export default Index
